import React from "react";
import {faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons"
import {faFacebookSquare} from "@fortawesome/free-brands-svg-icons"

import ContactItem from "./ContactItem";

import "./Footer.scss";
import {EMAIL, PHONE_NUMBER} from "../../../utils/consts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Footer() {
    const date = new Date();

    return <footer className="footer">
        <div className="container">
            <div className="columns is-multiline">
                <div className="column is-one-quarter">
                    <h1 className="title is-5">Godziny otwarcia</h1>
                    <p className="has-text-white">Poniedziałek - Piątek 8:00 - 16:00</p>
                </div>
                <div className="column is-one-quarter">
                    <h1 className="title is-5">Adres</h1>
                    <p className="has-text-white">
                        <span className="has-text-weight-bold">Auto Serwis Olaszewski</span><br/>
                        ul. Św. Stanisława 46 B<br/>
                        32-540 Trzebinia
                    </p>
                </div>
                <div className="column is-half">
                    <h1 className="title is-5">Kontakt</h1>
                    <p className="mb-4">
                        <ContactItem href={"tel:" + PHONE_NUMBER} icon={faPhoneAlt}>
                            {"Zadzwoń na " + PHONE_NUMBER}
                        </ContactItem>
                    </p>
                    <p className="mb-4">
                        <ContactItem href={"mailto:" + EMAIL} icon={faEnvelope}>
                            {"Napisz na " + EMAIL}
                        </ContactItem>
                    </p>
                    <p className="mb-4">
                        <a href="https://www.facebook.com/auto.serwis.olaszewski" target="_blank"
                           className="is-size-4 has-text-light" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookSquare}/>
                        </a>
                    </p>
                </div>
                <div className="column is-full">
                    <div className="columns">
                        <div className="column is-half">
                            <p className="is-size-7">&copy; Auto Serwis Olaszewski {date.getFullYear()}</p>
                        </div>
                        <div className="column is-half has-text-right">
                            <p className="is-size-7">made by <a href="https://softol.pl">softol.pl</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";

const MenuLink = ({to, name}) => {
    return <Link to={to} className="navbar-item" activeClassName="is-active">{name}</Link>
}

MenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

export default MenuLink;

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import "./ContactItem.scss";

const ContactItem = ({icon, href, children}) => {
    return <a href={href} className="footer-contact-button button is-light has-text-dark is-size-7-mobile">
        <FontAwesomeIcon icon={icon}/>&nbsp;{children}
    </a>
};

ContactItem.propTypes = {
    href: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
};

export default ContactItem;

import React from "react"
import CookieConsent from "react-cookie-consent";

import "../../scss/global.scss"
import "./layout.scss"

const Layout = ({children}) => {
    return <>
        {children}
        <CookieConsent
            location="bottom"
            buttonText="Akceptuję"
            cookieName="gatsby-gdpr-google-analytics"
            buttonStyle={{
                backgroundColor: "hsl(45, 96%, 48%)",
                color: "hsl(225, 54%, 42%)"
            }}
        >
            Korzystanie z witryny oznacza zgodę na wykorzystywanie plików cookie.
        </CookieConsent>
    </>
};

export default Layout

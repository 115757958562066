import React from 'react';
import PropTypes from 'prop-types';

import joinClassNames from "../../../helpers/joinClassNames";

const MenuBurger = (props) => {
    const toggleShow = () => {
        props.setIsOpen((prevIsOpen) => !prevIsOpen)
    };

    return (
        <div role="button"
             className={joinClassNames('navbar-burger', 'burger', props.isOpen ? 'is-active' : null)}
             tabIndex={0}
             aria-label="menu"
             aria-expanded="false"
             data-target="so-menu-items"
             onClick={toggleShow}
             onKeyDown={toggleShow}
             style={{outline: 'none'}}
        >
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
        </div>
    );
};

MenuBurger.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired
};

export default MenuBurger;

import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons"
import {Link} from "gatsby";

import MenuBurger from "./MenuBurger";
import MenuLink from "./MenuLink";
import joinClassNames from "../../../helpers/joinClassNames";
import "./Menu.scss";
import {EMAIL, PHONE_NUMBER} from "../../../utils/consts";

export default function Menu() {
    const [isOpen, setIsOpen] = useState(false);

    return <nav className="navbar has-shadow is-transparent is-spaced is-fixed-top" role="navigation"
                aria-label="main navigation">
        <div className="container">
            <div className="navbar-brand">
                <Link to="/" className="navbar-item">
                    <img src="../../logo.png" alt="Auto Serwis Olaszewski logo"/>
                </Link>
                <MenuBurger isOpen={isOpen} setIsOpen={setIsOpen}/>
            </div>

            <div id="so-menu-items" className={joinClassNames('navbar-menu', isOpen ? 'is-active' : null)}>
                <div className="navbar-end is-size-6 is-hidden-mobile contact-container">
                    <div className="navbar-item has-text-black">
                        Skontaktuj się:
                    </div>
                    <div className="navbar-item">
                        <a href={"tel:" + PHONE_NUMBER} className="has-text-primary">
                            <FontAwesomeIcon icon={faPhoneAlt}/> {PHONE_NUMBER}
                        </a>
                    </div>
                    <div className="navbar-item">
                        <a href={"mailto:" + EMAIL} className="has-text-primary">
                            <FontAwesomeIcon icon={faEnvelope}/> {EMAIL}
                        </a>
                    </div>
                </div>

                <div className="navbar-end">
                    <MenuLink to="/o-nas" name="O nas"/>
                    <MenuLink to="/zakres-uslug" name="Zakres usług"/>
                    <MenuLink to="/kontakt" name="Kontakt"/>
                </div>
            </div>
        </div>
    </nav>
}
